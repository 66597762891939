import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '.';

const Logo = props => {
  const [theme] = useContext(ThemeContext);
  return (
    <svg
      version="1.1"
      id="svg20"
      x="0px"
      y="0px"
      viewBox="0 0 395.8 151"
      style={{ 'enable-background': 'new 0 0 395.8 151' }}
    >
      <g id="Layer_1-2">
        <path style={{ fill: theme.fillTheme }} d="M35.8,18.1H0V3.3h35.8C32.6,6.5,32.7,15,35.8,18.1z" />
        <path
          style={{ fill: theme.fillBrand }}
          d="M135.7,77.6V3.3H70.2c4,4,4.1,11.1,0.4,14.9h49.6v55.1L92.9,34H75.6v63.2h15.8v-40l28.5,40h15.8V77.6z"
        />
        <path
          style={{ fill: theme.fillBrand }}
          d="M131.7,111.2h-16.1C96.9,129.6,68.9,136,68.9,136c-62.2-30.8-52-87.7-52-87.7h28.3v48.9h16.6V34H0v22.8
		c0,0,2.2,72.3,68.9,94.2c0,0,36.5-4,66.8-39.8L131.7,111.2z"
        />
        <path
          style={{ fill: theme.fillBrand }}
          d="M62.8,10c0,5.5-4.5,10-10,10s-10-4.5-10-10s4.5-10,10-10S62.8,4.5,62.8,10"
        />
      </g>
      <g id="text16" transform="translate(155.82,88.34)">
        <path
          id="path28"
          style={{ fill: theme.fillBrand }}
          d="M28.5-11.3c0-3.4-0.8-6.2-2.7-8.1c-1.6-1.6-3.9-2.6-7.3-3.1L14-23.1c-1.3-0.2-2.1-0.6-2.5-1.1
		c-0.6-0.6-0.7-1.2-0.7-1.7c0-1.6,1.3-3.4,4.5-3.4c1.6,0,4.7-0.2,7,2.2l5.7-5.7c-3.2-3.2-7.2-4.4-12.4-4.4C7.1-37.2,2-32.3,2-25.6
		c0,3.2,0.8,5.6,2.5,7.4c1.7,1.7,4.1,2.8,7.4,3.3l4.5,0.6c1.2,0.2,2,0.5,2.4,1c0.5,0.6,0.7,1.3,0.7,2.2c0,2.1-1.7,3.3-5.3,3.3
		c-3,0-6.3-0.7-8.2-2.6L0.3-4.6c3.7,3.8,8.3,4.9,14,4.9C22,0.3,28.5-3.8,28.5-11.3z"
        />
        <path
          id="path30"
          style={{ fill: theme.fillBrand }}
          d="M59.6,0v-8.1h-16v-6.5h13.7v-8.1H43.5v-6.2h16v-8.1H34.5V0H59.6z"
        />
        <path
          id="path32"
          style={{ fill: theme.fillBrand }}
          d="M92.8-11.7h-9.2c-0.6,2.1-1.7,3.9-4.7,3.9c-1.7,0-2.9-0.7-3.6-1.6c-0.8-1.1-1.4-2.4-1.4-9.1
		s0.6-8,1.4-9.1c0.7-0.9,1.9-1.6,3.6-1.6c3,0,4.1,1.9,4.7,3.9h9.2c-1.3-8.1-7-12-14-12c-4.3,0-7.6,1.5-10.3,4.1
		c-3.9,3.9-3.7,9.1-3.7,14.6S64.6-7.7,68.5-3.8c2.7,2.7,6,4.1,10.3,4.1C85.8,0.3,91.4-3.6,92.8-11.7z"
        />
        <path
          id="path34"
          style={{ fill: theme.fillBrand }}
          d="M124.9-13v-23.9h-9.1v23.7c0,3.4-2,5.4-5,5.4c-3,0-4.9-2-4.9-5.4v-23.7h-9.1V-13
		c0,8.1,6.3,13.3,13.9,13.3S124.9-4.9,124.9-13z"
        />
        <path
          id="path36"
          style={{ fill: theme.fillBrand }}
          d="M160.9,0l-8-15.4c2.9-1.5,6-4.6,6-9.6c0-6.1-4.4-11.9-12.6-11.9h-14.6V0h9.1v-13.7h3.1L150.4,0
		H160.9z M149.9-25c0,1.9-1.5,3.7-3.9,3.7h-5.1v-7.5h5.1C148.4-28.8,149.9-27,149.9-25z"
        />
        <path id="path38" style={{ fill: theme.fillBrand }} d="M174.6,0v-36.9h-9.1V0H174.6z" />
        <path id="path40" style={{ fill: theme.fillBrand }} d="M207.1-28.8v-8.1h-27.2v8.1h9.1V0h9.1v-28.8H207.1z" />
        <path
          id="path42"
          style={{ fill: theme.fillBrand }}
          d="M240-36.9h-9.9l-5.8,13.3l-5.8-13.3h-9.9L219.8-15V0h9.1v-15L240-36.9z"
        />
      </g>
    </svg>
  );
};

Logo.propTypes = {};

export default Logo;
