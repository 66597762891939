import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb as faLightbulbOn } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb as faLightbulbOff } from '@fortawesome/free-regular-svg-icons';

const ThemeToggler = ({ onThemeChange, currentTheme }) => {
  return (
    <button
      style={{ height: '1em' }}
      className="fixed z-20 right-0 p-2 mr-2 cursor-pointer text-xl text-teal-500"
      onClick={() => {
        onThemeChange(currentTheme === 'light' ? 'dark' : 'light');
      }}
    >
      <FontAwesomeIcon icon={currentTheme === 'light' ? faLightbulbOn : faLightbulbOff} />
    </button>
  );
};

export default ThemeToggler;
